const initialState = {
    imageBase64Str: null
  }
  export default function imageItems(state = {}, action) {
    console.log("Inside Reducer");
    switch (action.type) {
      case "STORE_IMAGE":
        console.warn("Store Image", action, state)
        return {...state, imageBase64Str: action.data}
        
      default:
        return state
    }
  
  }
  