import React, { useState, useEffect, useRef } from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import M from "materialize-css";
import { Sparklines, SparklinesLine, SparklinesBars } from 'react-sparklines';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';
import { map } from 'lodash';
import { title } from 'process';

const { tableau } = window;
const CarouselSlider = (props) => {
    let count = 0;
    const carousel = useRef(null);
    let slides = [];

    try {
        slides = props.dm;
        console.log("Carousel Props DM", props.dm);
        slides = map(slides, (slide) => {
            slide.highchartOpt = {
                "chart": {
                    "backgroundColor": "transparent",
                    "height": "100%"
                },
                "type": "column",
                "title": {
                    text: ''
                },
                "legend": {
                    "enabled": false,
                    "align": "right",
                    "verticalAlign": "top",
                    "itemStyle": {
                        "color": "#000"
                    },
                    "itemHoverStyle": {
                        "color": "rgba(255,255,255,0.8)"
                    },
                    "symbolHeight": 12,
                    "symbolWidth": 12,
                    "symbolRadius": 6
                },
                "xAxis": {
                    "categories": slide.sparkx[0],
                    "visible": false,
                    "labels": {
                        "enabled": false,
                        "style": {
                            "color": "rgba(255,255,255,0)"
                        }
                    }
                },
                "yAxis": {
                    "title": {
                        "enabled": false,
                        "align": "high",
                        "offset": -100,
                        "text": null,
                        "rotation": 0,
                        "x": 0,
                        "y": -30,
                        "style": {
                            "color": "#000",
                            "fontSize": "14px"
                        }
                    },
                    "gridLineColor": "rgba(255,255,255,0)",
                    "labels": {
                        "enabled": false,
                        "style": {
                            "color": "rgba(0,0,0,0.6)"
                        }
                    }
                },
                "series": [
                    {
                        "name": slide.title,
                        "color": "#fff",
                        "marker": {
                            "enabled": false
                        },
                        "data": slide.sparkv[0],
                    }
                ],
                "tooltip": {
                    formatter: function() {
                        
                        return this.x + '<br/>' + slide.prefix + this.y.toLocaleString() + slide.suffix;
                    },
                    "useHTML": true
                },
                "credits": {
                    "enabled": false
                },
            };
            return slide;
        });
        console.log("Slides", slides);
    } catch (e) {
        // console.error("props.dm not found for slides, setting slides=[]");
    }

    // const [slides] = useState([{
    //         title: 'SOCIAL MEDIA',
    //         value: '$100k',
    //         previousValue: '$90k',
    //         change: 1,
    //         goal: '$111k',
    //         variance: '90%'
    //     }]
    // );

    // Addition for highchart
    // const [chartOptions, setChartOptions] = useState(
    //     {
    //         credits: {
    //             enabled: false
    //         },
    //         title: {
    //             text: null
    //         },
    //         chart: {
    //             backgroundColor: 'transparent',
    //             type: 'spline',
    //             spacing: [40, 40, 40, 40],
    //             events: {
    //                 load: function () {
    //                     let items = document.getElementsByClassName('highcharts-legend-item');
    //                     for (let i = 0; i < items.length; i++) {
    //                         items[i].querySelector('path').setAttribute('stroke-width', 10);
    //                         items[i].querySelector('path').setAttribute('d', 'M 10 10 m -5 0 a 2 2 0 0 0 0 0');
    //                         items[i].querySelector('path').setAttribute("stroke-linecap", "round");
    //                     }
    //                 },
    //                 redraw: function () {
    //                     let items = document.getElementsByClassName('highcharts-legend-item');
    //                     for (let i = 0; i < items.length; i++) {
    //                         items[i].querySelector('path').setAttribute('stroke-width', 10);
    //                         items[i].querySelector('path').setAttribute('d', 'M 10 10 m -5 0 a 2 2 0 0 0 0 0');
    //                         items[i].querySelector('path').setAttribute("stroke-linecap", "round");
    //                     }
    //                 }
    //             },
    //             // legend: {
    //             //     align: 'right',
    //             //     verticalAlign: 'top',
    //             //     itemStyle: {
    //             //         color: '#fff'
    //             //     },
    //             //     itemHoverStyle: {
    //             //         color: 'rgba(255,255,255,0.8)'
    //             //     },
    //             //     symbolHeight: 12,
    //             //     symbolWidth: 12,
    //             //     symbolRadius: 6
    //             // },
    //             // xAxis: {
    //             //     categories: [],
    //             //     labels: {
    //             //         style: {
    //             //             color: 'rgba(255,255,255,0.6)'
    //             //         }
    //             //     }
    //             // },
    //             // yAxis: {
    //             //     title: {
    //             //         align: 'high',
    //             //         offset: -100,
    //             //         text: 'Cost by Campaigns',
    //             //         rotation: 0,
    //             //         x: 0,
    //             //         y: -30,
    //             //         style: {
    //             //             color: '#fff',
    //             //             fontSize: '14px'
    //             //         }
    //             //     },
    //             //     gridLineColor: 'rgba(255,255,255,0.2)',
    //             //     labels: {
    //             //         style: {
    //             //             color: 'rgba(255,255,255,0.6)'
    //             //         }
    //             //     }
    //             // },
    //             series: [],
    //             noData: {
    //                 style: {
    //                     fontWeight: 'normal',
    //                     fontSize: '12px',
    //                     color: '#fff'
    //                 }
    //             },
    //             lang: {
    //                 noData: "No data available",
    //                 decimalPoint: '.'
    //             },
    //         }
    //     });

    useEffect(() => {
        var options = {
            indicators: true
        }
        var elem = document.querySelectorAll('.carousel');
        M.Carousel.init(elem, options);

        // slides = map(slides, (slide) => {
        //     slide.highchartOpt = {
        //         "legend": {
        //             "align": "right",
        //             "verticalAlign": "top",
        //             "itemStyle": {
        //                 "color": "#fff"
        //             },
        //             "itemHoverStyle": {
        //                 "color": "rgba(255,255,255,0.8)"
        //             },
        //             "symbolHeight": 12,
        //             "symbolWidth": 12,
        //             "symbolRadius": 6
        //         },
        //         "xAxis": {
        //             "categories": slide.sparkx,
        //             "labels": {
        //                 "style": {
        //                     "color": "rgba(255,255,255,0.6)"
        //                 }
        //             }
        //         },
        //         "yAxis": {
        //             "title": {
        //                 "align": "high",
        //                 "offset": -100,
        //                 "text": "",
        //                 "rotation": 0,
        //                 "x": 0,
        //                 "y": -30,
        //                 "style": {
        //                     "color": "#fff",
        //                     "fontSize": "14px"
        //                 }
        //             },
        //             "gridLineColor": "rgba(255,255,255,0.2)",
        //             "labels": {
        //                 "style": {
        //                     "color": "rgba(255,255,255,0.6)"
        //                 }
        //             }
        //         },
        //         "series": [
        //             {
        //                 "name": slide.title,
        //                 "color": "#7AFCBB",
        //                 "marker": {
        //                     "enabled": false
        //                 },
        //                 "data": slide.sparkv,
        //             }
        //         ],
        //         "tooltip": {
        //             "useHTML": true
        //         }
        //     };
        //     return slide;
        // });
        

    //     setChartOptions({
    //         legend: {
    //             align: 'right',
    //             verticalAlign: 'top',
    //             itemStyle: {
    //                 color: '#fff'
    //             },
    //             itemHoverStyle: {
    //                 color: 'rgba(255,255,255,0.8)'
    //             },
    //             symbolHeight: 12,
    //             symbolWidth: 12,
    //             symbolRadius: 6
    //         },
    //         xAxis: {
    //             categories: sparkXAxis,
    //             labels: {
    //                 style: {
    //                     color: 'rgba(255,255,255,0.6)'
    //                 }
    //             }
    //         },
    //         yAxis: {
    //             title: '',
    //             gridLineColor: 'rgba(255,255,255,0.2)',
    //             labels: {
    //                 style: {
    //                     color: 'rgba(255,255,255,0.6)'
    //                 },
    //                 formatter: function () {
    //                     if (metric.toLowerCase() === 'ctr') {
    //                         return this.axis.defaultLabelFormatter.call(this) + '%';
    //                     } else if (metric.toLowerCase() === 'cost' || metric.toLowerCase() === 'revenue') {
    //                         return '$' + this.axis.defaultLabelFormatter.call(this);
    //                     } else {
    //                         return this.axis.defaultLabelFormatter.call(this);
    //                     }
    //                 }
    //             }
    //         },
    //         series: sparkYAxis,
    //         tooltip: {
    //             formatter: function () {
    //                 if (metric.toLowerCase() === 'ctr') {
    //                     return this.x + '<br/>' + this.y.toLocaleString() + '%';
    //                 } else if (metric.toLowerCase() === 'cost' || metric.toLowerCase() === 'revenue') {
    //                     return this.x + '<br/>' + '$' + this.y.toLocaleString();
    //                 } else {
    //                     return this.x + '<br/>' + this.y.toLocaleString();
    //                 }

    //             },
    //             useHTML: true
    //         },
    //     });
    }, [props]);

    let navigatePrevious = (e) => {
        e.preventDefault();
        let instance = M.Carousel.getInstance(carousel.current);
        instance.prev();
    }

    let navigateNext = (e) => {
        e.preventDefault();
        let instance = M.Carousel.getInstance(carousel.current);
        instance.next();
    }

    // console.log("b4return", slides);
    console.log("Carousel Options", slides);
    return (
        <div>
            <div className="carouselOuter" style={{ position: 'relative' }}>
                <div ref={carousel} className="carousel">
                    {slides.map((slide, index) => {
                        if (count < 7)
                            count++;
                        else
                            count = 1;

                        let alert = <span className="icon icon-medium icon-up-arrow"></span>;
                        if (!slide.change) {
                            alert = <span className="icon icon-medium icon-down-arrow"></span>;
                        }

                        return (
                            <div key={index} className="carousel-item flex-container flex-column goalMeter">
                                <div className={`bg-img card-${count}`}></div>
                                <div className="flex-item"><h2 className="cardTitle m-0 text-medium">{slide.title}</h2></div>
                                <div className="flex-item"><h1 className="cardValue m-0 text-large">{slide.prefix + slide.value + slide.suffix}</h1></div>
                                <div className="flex-item"><div className="row flex m-0">
                                    <div className="col s12 flex-container flex-align-center p-0">
                                        <span className="cardPreviousValue bold text-small bold mr-20">{slide.comparison}</span>
                                        {alert}
                                    </div>
                                </div>
                                </div>

                                <div className='highcharts-carousel p-absolute-bot'>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={slide.highchartOpt}
                                    />
                                </div>
                                {/* <div className="flex-item p-relative"><div className="goal-meter p-absolute p-spark"><Sparklines data={slide.sparkv} width={120} height={30} margin={5}><SparklinesLine color="white" /></Sparklines></div></div> */}
                                {/* <div className="flex-item"><h4 className="cardSummary text-small center bold">{slide.variance} of {slide.goal}</h4></div> */}
                                {/* <div className="flex-item p-relative"><div className="goal-meter p-absolute p-absolute-bot"><ProgressBar completed={parseInt(slide.variance)} customLabel=' ' labelClassName='button' animateOnRender={true} height='5px' bgColor='#fff' baseBgColor='rgba(224, 224, 222,0.3)' /></div></div> */}
                            </div>
                        );
                    })}
                </div>
                <div className="navigation">
                    <div className="navigation-left">
                        <a href="/" onClick={navigatePrevious} className="movePrevCarousel middle-indicator-text content-indicator"><span className="icon icon-medium icon-left-arrow"></span></a>
                    </div>

                    <div className="navigation-right">
                        <a href="/" onClick={navigateNext} className=" moveNextCarousel middle-indicator-text content-indicator"><span className="icon icon-medium icon-right-arrow"></span></a>
                    </div>
                </div>
            </div>
        
            {/* <div className="carouselNote">*Note : On first load, the slider will be showing data available for a rollback period of last 3 months available on the worksheet till today.</div> */}
        </div>
    );
}

export default CarouselSlider;