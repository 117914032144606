import React, { ChangeEvent, useState } from "react";
const { tableau } = window;

function FileUploadSingle(props) {
  const [file, setFile] = useState();
  function handleChange(e) {
    console.log(e.target.files);
    getBase64(e.target.files[0], props);
    setFile(URL.createObjectURL(e.target.files[0]));
  }

  function getBase64(file, props) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {

      tableau.extensions.settings.set('logo64', reader.result);
      tableau.extensions.settings.saveAsync().then(() => {
        console.log("Settings saved");
        console.log("Logo64",tableau.extensions.settings.get('logo64'));
      });
      if(props.storeImageBase64Handler) {
        props.storeImageBase64Handler(reader.result);
      }
      
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  console.log("Dialog File", file);

  return (
    <div className="dialogHeading">
      <h2>Change Logo</h2>
      <div className="dialog-outside-header">
        <div className="dialogHeader">
          <img src={file} />
          <input type="file" onChange={handleChange} />
          <span className="note">Recommended image size : 260px X 90px</span>
        </div>
      </div>
      
    </div>
  );
}

export default FileUploadSingle;
