import React from 'react';

import { BrowserRouter as Router, Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from '../src/reducers/index';

import './App.css';

import Footer from './components/footer/footer';
import HomeContainer from './container/HomeContainer';
import HeaderContainer from './container/HeaderContainer';
import Dialog from './components/dialog';
import GetStarted from './components/GetStarted';

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  window.__REDUX_DEVTOOLS_EXTENSION__()
)

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        {/* <HeaderContainer /> */}
        <HomeContainer />
        <Router>
          <Route exact path="/dialog" component={Dialog} />
          <Route exact path="/GetStarted" component={GetStarted} />
        </Router>
        <Footer />
      </div>
    </Provider>
  );
}

export default App;