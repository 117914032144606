import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { map, reduce, uniq, each, findIndex, chain, indexOf, isNull } from "lodash";
import DatePicker from "react-datepicker";
import GetStarted from "./GetStarted";
import Carousel from "./carousel/carousel";
import Spline from "./highcharts/spline/spline";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderContainer from "../container/HeaderContainer";

const { tableau } = window;
const DEFAULT_INTERVAL_IN_MIN = "5";
toast.configure();
const HomeComponent = (props) => {

    const [dashboardSheets, setDashboardSheets] = useState(null);
    const [dashboardSheetsData, setDashboardSheetsData] = useState(null);
    const [getStartedDM, setGetStartedDM] = useState(null);
    const [startDate, setStartDate] = useState(new Date('01/01/1975'));
    const [endDate, setEndDate] = useState(new Date());

    const [splineDM, setSplineDM] = useState(null);
    const [carouselDM, setCarouselDM] = useState(null);
    const [redirectToCharts, setRedirectToCharts] = useState(false);
    const [redirectToGetStarted, setRedirectToGetStarted] = useState(false);
    const [chartGenerationDMState, setChartGenerationDMState] = useState(null);

    var campaignIdIndex = 0;

    const odrId = "odrId";
    const mdrId = "mdrId";

    const configure = () => {
        let popupUrl = "GetStarted";
        tableau.extensions.ui.displayDialogAsync(popupUrl, DEFAULT_INTERVAL_IN_MIN,
            { height: 500, width: 500 }).then((closePayload) => {
            });
    };
    
    // useEffect(() => {
    //     if(savedStartDate) {
    //         let savedStartDate = moment(tableau.extensions.settings.get('savedStartDate')).toDate();
    //         recalculateChart(savedStartDate, endDate);
    //         console.log("Recalculating chart because of start date changed to", savedStartDate);
    //     }
    //     if(!savedStartDate) {
    //         recalculateChart( startDate, endDate);
    //         console.log("Recalculating chart because of start date changed to", startDate);
    //     }
    // }, [startDate]);

    useEffect(() => {

        tableau.extensions.initializeAsync({ 'configure': configure }).then(async () => {
            setDashboardSheets(tableau.extensions.dashboardContent.dashboard.worksheets);
            setDashboardSheetsData(
                await Promise.all(map(tableau.extensions.dashboardContent.dashboard.worksheets,
                    async (sheet) => {
                        let sheetData = await sheet.getSummaryDataAsync();
                        return sheetData;
                    })));
            

            let chartGenerationSavedDM = getChartGenerationSavedDM();
            if (chartGenerationSavedDM) {
                setChartGenerationDMState(chartGenerationSavedDM);
            }
            tableau.extensions.settings.addEventListener(tableau.TableauEventType.SettingsChanged, (settingsEvent) => {
                console.log("Settings changed", settingsEvent.newSettings);
                if(settingsEvent.newSettings.logo64) {
                    props.storeImageBase64Handler(settingsEvent.newSettings.logo64);
                }
              });
        });

        // For Configuration Dialog
        tableau.extensions.initializeDialogAsync().then(function (openPayload) {
            // The openPayload sent from the parent extension in this example is the
            // default time interval for the refreshes.  This could alternatively be stored
            // in settings, but is used in this sample to demonstrate open and close payloads.
            // code goes here
            });

        function closeDialog() {
            // Save the settings with tableau.extensions.settings.saveAsync()
            // Or pass the new configuration setting in the close payload
            tableau.extensions.ui.closeDialog('NewInterval');
            // console.log("Settings saved");
            }
    }, []);

    console.log("Dashboard Sheets Data",dashboardSheetsData);

    const getChartGenerationSavedDM = () => {
        let wSheet = tableau.extensions.settings.get('wSheet');
        // Refactor code tableau settings
        if (wSheet &&
            tableau.extensions.settings.get('multiSelectList1') &&
            tableau.extensions.settings.get('multiSelectList2') &&
            tableau.extensions.settings.get('kpiDimenMetric') &&
            tableau.extensions.settings.get('finalSavedDimension') &&
            tableau.extensions.settings.get('savedStartDate') &&
            tableau.extensions.settings.get('savedEndDate')) {
            var multiSelectSavedArrayList = map(tableau.extensions.settings.get('multiSelectList1').split(','), (key) => {
                return { name: key, id: key };
            });
            var multiSelectSavedTrendArrayList = map(tableau.extensions.settings.get('multiSelectList2').split(','), (key) => {
                return { name: key, id: key };
            });

            let chartGenerationSavedDM = {
                selectedSheet: wSheet,
                kpisMultiSelectValues: multiSelectSavedArrayList,
                dimensionsMultiSelectValues: multiSelectSavedTrendArrayList,
                selectedKpiMetric: tableau.extensions.settings.get('kpiDimenMetric'),
                finalDimension: tableau.extensions.settings.get('finalSavedDimension'),
                savedStartDate: tableau.extensions.settings.get('savedStartDate'),
                savedEndDate: tableau.extensions.settings.get('savedEndDate')
            }

            return chartGenerationSavedDM;
        } else {
            return null;
        }
    }

    const generateGetStartedDM = async () => {
        // console.log("Generating Get started", dashboardSheets);
        // console.log("Dashboard Sheets Data", dashboardSheetsData);

        if (dashboardSheets && dashboardSheetsData) {
            let sheetsDropdownList = map(dashboardSheets, (sheet, index) => {
                return {
                    id: index,
                    value: sheet.name
                };
            });
            console.log("sheetsDropdownList", sheetsDropdownList);
            let kpisDimensionsDropDownLists = reduce(dashboardSheetsData, (acc, currentSheetData, currentSheetIndex) => {
                let currentKpisDimensionsList = {
                    "kpisList": [],
                    "dimensionsList": []
                };
                try {
                    if(currentSheetData._columns) {
                        var dateIndex = findIndex(currentSheetData._columns, (obj)=> {
                            console.log("Obj Formatted", obj._fieldName);
                            return obj._fieldName.includes("Date") == true;
                        });
                        if(dateIndex == 0) {
                            campaignIdIndex = 1;
                        } 
                    }

                    each(currentSheetData._columns, (currentDataPoint) => {
                        
                        // if(currentDataPoint.index>1) {
                        //     // let nomen = String(currentDataPoint._fieldId);
                        //     // let formattedNomen = nomen.split(/\[(.*?)\]/g)[3];
                        //     let formattedNomen = String(currentDataPoint._fieldName).split(/\((.*?)\)/g)[1];
                        //     console.log("Nomenclature", formattedNomen);
                        // }

                        if(currentDataPoint._index != campaignIdIndex && currentDataPoint.index != dateIndex) {
                            currentKpisDimensionsList.kpisList.push(String(currentDataPoint._fieldName).split(/\((.*?)\)/g)[1]);
                        }
                    });
                    each(currentSheetData._data, (currentDataPoint) => {

                        currentKpisDimensionsList.dimensionsList.push(currentDataPoint[campaignIdIndex]._formattedValue);
                    });
                    currentKpisDimensionsList.kpisList = uniq(currentKpisDimensionsList.kpisList);
                    currentKpisDimensionsList.dimensionsList = uniq(currentKpisDimensionsList.dimensionsList);
                    
                    // Old KPIS and Dimensions List
                    // each(currentSheetData._data, (currentDataPoint) => {
                    //     // console.log("Current Data Point",currentDataPoint);
                    //     currentKpisDimensionsList.kpisList.push(currentDataPoint[2]._formattedValue);
                    //     currentKpisDimensionsList.dimensionsList.push(currentDataPoint[1]._value);
                    // });
                    // currentKpisDimensionsList.kpisList = uniq(currentKpisDimensionsList.kpisList);
                    // currentKpisDimensionsList.dimensionsList = uniq(currentKpisDimensionsList.dimensionsList);
                } catch (e) {
                    // console.log("Sheet formatting wrong", e);
                }
                console.log("Current KPIS Dimension List",currentKpisDimensionsList);
                acc[currentSheetIndex] = currentKpisDimensionsList;
                return acc;
            }, {});
            setGetStartedDM({
                sheetsDropdownList: sheetsDropdownList,
                kpisDimensionsDropDownLists: kpisDimensionsDropDownLists
            });
        }
    };

    const onDateRangeUpdate = (dates) => {
        const [startDt, endDt] = dates;
        setStartDate(startDt);
        setEndDate(endDt);
        if (startDt && endDt) {
            recalculateChart(startDt, endDt);
        }
    }

    const outsideDateRange = () => {
        toast.error('Outside the Date Range of Data or Worksheet not properly set', {
            toastId: odrId,
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    const changeStartDateRecalculation = (chartGenerationDM) => {
        if(dashboardSheets && dashboardSheetsData) {
            let finalSelectedSheet = chartGenerationDM.selectedSheet;
            let relevantSheetDataIdx = findIndex(dashboardSheets, function (o) {
                return o.name == finalSelectedSheet;
            });
            if(dashboardSheetsData[relevantSheetDataIdx]._columns) {
                var dateIndex = findIndex(dashboardSheetsData[relevantSheetDataIdx]._columns, (obj)=> {
                    return obj._fieldName.includes("Date") == true;
                });
            }

            // New Addition for date rearranging

            let dateArray = [];
            let uniqNewDateArray = [];
            let dateFormattedArray = [];
            let uniqNewFormattedDateArray = [];

            for (let i = 0; i < dashboardSheetsData[relevantSheetDataIdx]._data.length; i++) {
                dateArray.push(dashboardSheetsData[relevantSheetDataIdx]._data[i][dateIndex]._value);
            };

            for (let i = 0; i < dashboardSheetsData[relevantSheetDataIdx]._data.length; i++) {
                dateFormattedArray.push(dashboardSheetsData[relevantSheetDataIdx]._data[i][dateIndex]._formattedValue);
            };

            let newDateArray = dateArray.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a) - new Date(b);
            });

            let newDateFormattedArray = dateFormattedArray.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a) - new Date(b);
            });

            uniqNewDateArray = uniq(newDateArray);

            uniqNewFormattedDateArray = uniq(newDateFormattedArray);

            console.log("UNIQ New Formatted Date Array", uniqNewFormattedDateArray);

            let d = uniqNewDateArray[uniqNewDateArray.length - 1];

            let dateMetricDiff = moment(moment(String(uniqNewDateArray[1])).toDate()).diff(moment(String(uniqNewDateArray[0])).toDate(), 'days');

            console.log("Date Metric Difference", dateMetricDiff);

            let tempStartDate = moment(moment(d, 'YYYYMMDD').toDate()).subtract(3, 'months').format('YYYYMMDD');

            if(dateMetricDiff == 7) {
                tempStartDate = moment(moment(d, 'YYYYMMDD').toDate()).subtract(12, 'weeks').format('YYYYMMDD');
            }

            else if(dateMetricDiff > 27 && dateMetricDiff < 32) {
                tempStartDate = moment(moment(d, 'YYYYMMDD').toDate()).subtract(3, 'months').format('YYYYMMDD');
            }

            else if(dateMetricDiff > 364) {
                tempStartDate = moment(moment(d, 'YYYYMMDD').toDate()).subtract(3, 'years').format('YYYYMMDD');
            }

            let tempEndDate = moment(moment(d, 'YYYYMMDD').toDate()).subtract(1, 'days').format('YYYYMMDD');

            let momentStartDate = moment(tempStartDate, 'YYYYMMDD').toDate();

            let momentEndDate = moment(tempEndDate, 'YYYYMMDD').toDate();

            if(!tableau.extensions.settings.get('savedStartDate')) {
                tableau.extensions.settings.set('savedStartDate', momentStartDate);
            }
            setStartDate(moment(tableau.extensions.settings.get('savedStartDate')).toDate());
            
            if(!tableau.extensions.settings.get('savedEndDate')) {
                tableau.extensions.settings.set('savedEndDate', momentEndDate);
            }
            setEndDate(moment(tableau.extensions.settings.get('savedEndDate')).toDate());
        }
        else {
            console.log("Something empty", dashboardSheets, dashboardSheetsData);
        }
    }

    const getChartDMFromGenerationDM = (chartGenerationDM, startDtParam, endDtParam) => {
        // console.log("Moment Start Date 1975 checker", moment(startDate).format('YYYYMMDD'), moment('01/01/1975').format('YYYYMMDD'));
        if(moment(startDate).format('YYYYMMDD') == moment('01/01/1975').format('YYYYMMDD')) {
            setStartDate(moment(tableau.extensions.settings.get('savedStartDate')).toDate());
        }

        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;

        console.log("Moment End Date", moment(endDate).format('YYYYMMDD'));
        console.log("Moment Today", moment(today).format('YYYYMMDD'));

        if(moment(endDate).format('YYYYMMDD') == moment(today).format('YYYYMMDD')) {
            setEndDate(moment(tableau.extensions.settings.get('savedEndDate')).toDate());
        }
        
        // let startDateConsidered = startDtParam ? startDtParam : startDate;
        let startDateConsidered = startDtParam ? startDtParam : moment(tableau.extensions.settings.get('savedStartDate')).toDate();
        // let endDateConsidered = endDtParam ? endDtParam : endDate;
        let endDateConsidered = endDtParam ? endDtParam : moment(tableau.extensions.settings.get('savedEndDate')).toDate();

        if (dashboardSheets && dashboardSheetsData) {
            let finalSelectedSheet = chartGenerationDM.selectedSheet;
            let finalKpisMultiSelectValues = chartGenerationDM.kpisMultiSelectValues;
            let finalDimensionsMultiSelectValues = chartGenerationDM.dimensionsMultiSelectValues;
            let finalSelectedKpiMetric = chartGenerationDM.selectedKpiMetric;

            let relevantSheetDataIdx = findIndex(dashboardSheets, function (o) {
                return o.name == finalSelectedSheet;
            });

            if(dashboardSheetsData[relevantSheetDataIdx]._columns) {
                var dateIndex = findIndex(dashboardSheetsData[relevantSheetDataIdx]._columns, (obj)=> {
                    console.log("Chart DM Obj Formatted", obj._fieldName);
                    return obj._fieldName.includes("Date") == true;
                });
                if(dateIndex == 0) {
                    campaignIdIndex = 1;
                }
            }

            console.log("date Index", dateIndex);

            // If values missing, then addition -------->>>>>

            let dateArray = [];
            let dateFormattedArray = [];
            let uniqNewDateArray = [];
            let uniqNewFormattedDateArray = [];

            for(let i=0; i<dashboardSheetsData[relevantSheetDataIdx]._data.length; i++) {
                dateArray.push(dashboardSheetsData[relevantSheetDataIdx]._data[i][dateIndex]._value);
            };

            for(let i=0; i<dashboardSheetsData[relevantSheetDataIdx]._data.length; i++) {
                dateFormattedArray.push(dashboardSheetsData[relevantSheetDataIdx]._data[i][dateIndex]._formattedValue);
            };

            let newDateArray = dateArray.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a) - new Date(b);
            });

            let newFormattedDateArray = dateFormattedArray.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a) - new Date(b);
            });

            uniqNewDateArray = uniq(newDateArray);
            uniqNewFormattedDateArray = uniq(newFormattedDateArray);

            console.log("New Date Array", uniqNewDateArray);

            // Values missing addition ended <<<<<<<<-----------

            console.log("between chart days first date range", moment(moment(String(uniqNewDateArray[1])).toDate()).diff(moment(String(uniqNewDateArray[0])).toDate(), 'days'));
            // console.log("Total Time Range of selected dates", moment(moment(String(dashboardSheetsData[relevantSheetDataIdx]._data[dashboardSheetsData[relevantSheetDataIdx]._data.length - 1][dateIndex]._value)).toDate()).diff(moment(String(dashboardSheetsData[relevantSheetDataIdx]._data[0][dateIndex]._value)).toDate(), 'days'));
            // var selectedTimeRange = moment(moment(String(dashboardSheetsData[relevantSheetDataIdx]._data[dashboardSheetsData[relevantSheetDataIdx]._data.length - 1][dateIndex]._value)).toDate()).diff(moment(String(dashboardSheetsData[relevantSheetDataIdx]._data[0][dateIndex]._value)).toDate(), 'days');
            
            if (relevantSheetDataIdx == -1 && dashboardSheetsData[relevantSheetDataIdx]) {
                console.error("Selected Sheet not found");
                return;
            }

            var finalDimension = dashboardSheetsData[relevantSheetDataIdx]._columns[campaignIdIndex]._fieldName;

            let selectedDaysDifference = moment(endDateConsidered).diff(startDateConsidered, 'days');
            // let betweenChartDaysDifference = moment(moment(String(dashboardSheetsData[relevantSheetDataIdx]._data[1][4]._value)).toDate()).diff(moment(String(dashboardSheetsData[relevantSheetDataIdx]._data[0][4]._value)).toDate(), 'days');
            if(moment(moment(String(dashboardSheetsData[relevantSheetDataIdx]._data[1][dateIndex]._value)).toDate()).format('YYYYMMDD') < moment(Date('01/01/1970')).format('YYYYMMDD')) {
                var betweenChartDaysDifference = moment(moment(String(uniqNewFormattedDateArray[1])).toDate()).diff(moment(String(uniqNewFormattedDateArray[0])).toDate(), 'days');
            } else {
                var betweenChartDaysDifference = moment(moment(String(uniqNewDateArray[1])).toDate()).diff(moment(String(uniqNewDateArray[0])).toDate(), 'days');
            }
            console.log("betweenChartDaysDifference & selectedDaysDifference", betweenChartDaysDifference, selectedDaysDifference);

            let differenceFlag = 0;
            if(betweenChartDaysDifference>26) {
                if (selectedDaysDifference < (betweenChartDaysDifference-4)) {
                    differenceFlag = 0;
                }
                else {
                    differenceFlag = 1;
                }
            }
            else {
                if (selectedDaysDifference < (betweenChartDaysDifference-1)) {
                    differenceFlag = 0;
                }
                else {
                    differenceFlag = 1;
                }
            }

            if (differenceFlag===0) {
                console.error("Outside the Date Range of Data or Worksheet not properly set");
                const minDateRange = () => {
                    toast.error('Keep atleast '+ (betweenChartDaysDifference-1) + ' days difference between the dates', {
                        toastId: mdrId,
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    });
                }
                minDateRange();
                return;
            } else {

                dashboardSheetsData[relevantSheetDataIdx]._data.sort(function compare(a, b) {
                    var dateA = new Date(a[1]._value);
                    var dateB = new Date(b[1]._value);
                    return dateA - dateB;
                  });
                
                console.log("Moment checker", moment(dashboardSheetsData[relevantSheetDataIdx]._data[0][dateIndex]._formattedValue).format('YYYYMMDD'));

                // if ((moment(startDateConsidered).format('YYYYMMDD') > dashboardSheetsData[relevantSheetDataIdx]._data[dashboardSheetsData[relevantSheetDataIdx]._data.length - 1][4]._value) ||
                //     (moment(endDateConsidered).format('YYYYMMDD') < dashboardSheetsData[relevantSheetDataIdx]._data[0][4]._value)) {
                if ((moment(startDateConsidered).format('YYYYMMDD') > moment(dashboardSheetsData[relevantSheetDataIdx]._data[dashboardSheetsData[relevantSheetDataIdx]._data.length - 1][dateIndex]._value).format('YYYYMMDD')) ||
                    (moment(endDateConsidered).format('YYYYMMDD') < moment(dashboardSheetsData[relevantSheetDataIdx]._data[0][dateIndex]._value).format('YYYYMMDD'))) {
                    console.error("Outside the Date Range of Data or Worksheet not properly set");
                    outsideDateRange();
                    return;
                } else {

                    if (!tableau.extensions.settings.get('wSheet')) {
                        tableau.extensions.settings.set('wSheet', chartGenerationDM.selectedSheet);
                    }

                    if (!tableau.extensions.settings.get('multiSelectList1')) {
                        let mS1 = [];
                        map(chartGenerationDM.kpisMultiSelectValues, (mS1Value) => {
                            mS1.push(mS1Value.name);
                        }, []);
                        let stringList = mS1.join(',');
                        tableau.extensions.settings.set('multiSelectList1', stringList);
                    }

                    if (!tableau.extensions.settings.get('multiSelectList2')) {
                        let mS2 = [];
                        map(chartGenerationDM.dimensionsMultiSelectValues, (mS2Value) => {
                            mS2.push(mS2Value.name);
                        }, []);
                        let stringTList = mS2.join(',');
                        tableau.extensions.settings.set('multiSelectList2', stringTList);
                    }

                    if (!tableau.extensions.settings.get('kpiDimenMetric')) {
                        tableau.extensions.settings.set('kpiDimenMetric', chartGenerationDM.selectedKpiMetric);
                    }

                    if(!tableau.extensions.settings.get('finalSavedDimension')) {
                        tableau.extensions.settings.set('finalSavedDimension', finalDimension);
                    }
                    
                    console.log("Saved Start Date", tableau.extensions.settings.get('savedStartDate'));
                    console.log("Saved End Date", tableau.extensions.settings.get('savedEndDate'));

                    if (!tableau.extensions.settings.get('selectedSavingOption')) {
                        tableau.extensions.settings.set('selectedSavingOption', chartGenerationDM.selectedSaving);
                    }

                    if (tableau.extensions.settings.get('wSheet') &&
                        tableau.extensions.settings.get('multiSelectList1') &&
                        tableau.extensions.settings.get('multiSelectList2') &&
                        tableau.extensions.settings.get('kpiDimenMetric') &&
                        tableau.extensions.settings.get('finalSavedDimension') &&
                        tableau.extensions.settings.get('savedStartDate') &&
                        tableau.extensions.settings.get('savedEndDate')) {
                            if(tableau.extensions.settings.get('selectedSavingOption')!=='No') {
                                tableau.extensions.settings.saveAsync().then(() => {
                                    // console.log("Settings saved");
                                    
                                });
                            }
                            else {
                                // console.log("Settings not saved");
                            }
                    }

                    let relevantSheetData = dashboardSheetsData[relevantSheetDataIdx];
                    // console.log("relevantSheetData", relevantSheetData);

                    relevantSheetData._data.sort(function compare(a, b) {
                        var dateA = new Date(a[1]._value);
                        var dateB = new Date(b[1]._value);
                        return dateA - dateB;
                      });

                    let splineIntermediateDM = {};
                    let carouselIntermediateDM = {};

                    let formattingConf = {
                    //     'ctr': {
                    //         'append': '%',
                    //         'precision': 2
                    //     },
                    //     'revenue': {
                    //         'prepend': '$'
                    //     },
                    //     'cost': {
                    //         'prepend': '$'
                    //     },
                        'default': {}
                    }

                    let formatStringFromConf = (val) => {
                        let finalVal = val;
                        if (!finalVal) {
                            return;
                        }
                        // let prependStr = '';
                        // let appendStr = '';
                        // let roundPrecision = conf.precision != undefined ? conf.precision : 0;
                        // if (conf.prepend) {
                        //     if (conf.prepend == '$') {
                        //         prependStr += '$';
                        //     }
                        // }
                        // if (conf.append) {
                        //     if (conf.append == '%') {
                        //         appendStr += '%';
                        //     }
                        // }
                        // if(finalVal>99999) {
                        //     finalVal = parseFloat(finalVal.toFixed(0));
                        // }
                        // else if(finalVal>999 && finalVal<100000) {
                        //     finalVal = parseFloat(finalVal.toFixed(1));
                        // }
                        // else if(finalVal<1000) {
                        //     finalVal = parseFloat(finalVal.toFixed(2));
                        // }

                        if(finalVal > 99 || finalVal % 1 == 0) {
                            finalVal = parseFloat(finalVal.toFixed(0));
                        }
                        else {
                            finalVal = parseFloat(finalVal.toFixed(2));
                        }
                        
                        return finalVal.toLocaleString();
                    }

                    // let convertToInternationalCurrencySystem = (labelValue) => {

                    //     // Nine Zeroes for Billions
                    //     return Math.abs(Number(labelValue)) >= 1.0e+9

                    //     ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
                    //     // Six Zeroes for Millions 
                    //     : Math.abs(Number(labelValue)) >= 1.0e+6

                    //     ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
                    //     // Three Zeroes for Thousands
                    //     : Math.abs(Number(labelValue)) >= 1.0e+3

                    //     ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

                    //     : Math.abs(Number(labelValue));

                    // }

                    let metricIndex = findIndex(relevantSheetData._columns, function(obj) {
                        return String(obj._fieldName).split(/\((.*?)\)/g)[1] == finalSelectedKpiMetric;
                    });

                    console.log("Metric Index", metricIndex);

                    each(relevantSheetData._data, (currDataPoint) => {

                        // Populating Spline DM
                        if (
                            // finalSelectedKpiMetric == currDataPoint[2]._formattedValue &&
                            moment(currDataPoint[dateIndex]._value).format('YYYYMMDD') >= moment(startDateConsidered).format('YYYYMMDD')
                            && moment(currDataPoint[dateIndex]._value).format('YYYYMMDD') <= moment(endDateConsidered).format('YYYYMMDD')
                            && -1 !== findIndex(finalDimensionsMultiSelectValues, function (o) { return o.name == currDataPoint[campaignIdIndex]._formattedValue; })
                        ) {
                            if (!splineIntermediateDM[currDataPoint[campaignIdIndex]._value]) {
                                splineIntermediateDM[currDataPoint[campaignIdIndex]._value] = {
                                    xAxisArr: [],
                                    yAxisArr: [],
                                    yAxisFormattedArr: []
                                }
                            }

                            splineIntermediateDM[currDataPoint[campaignIdIndex]._value].xAxisArr.push(currDataPoint[dateIndex]._formattedValue);
                            splineIntermediateDM[currDataPoint[campaignIdIndex]._value].yAxisArr.push(currDataPoint[metricIndex]._value);
                            splineIntermediateDM[currDataPoint[campaignIdIndex]._value].yAxisFormattedArr.push(currDataPoint[metricIndex]._formattedValue);
                            
                        }

                        // console.log("Intermediate Spline DM", splineIntermediateDM);

                        // //Populating Carousel DM : Sum
                        // if (currDataPoint[1]._value >= moment(startDateConsidered).format('YYYYMMDD')
                        //     && currDataPoint[1]._value <= moment(endDateConsidered).format('YYYYMMDD')
                        //     && -1 !== findIndex(finalKpisMultiSelectValues, function (o) { return o.name == currDataPoint[2]._formattedValue; })
                        // ) {

                        //     let currentKpisDimensionKey = currDataPoint[2]._formattedValue;

                        //     if (!carouselIntermediateDM[currentKpisDimensionKey]) {
                        //         carouselIntermediateDM[currentKpisDimensionKey] = {}
                        //     }
                        //     if (carouselIntermediateDM[currentKpisDimensionKey].sum == undefined) {
                        //         carouselIntermediateDM[currentKpisDimensionKey].sum = 0.0;
                        //     }
                        //     carouselIntermediateDM[currentKpisDimensionKey].sum += currDataPoint[5]._value;
                        //     if (carouselIntermediateDM[currentKpisDimensionKey].maxValue == undefined) {
                        //         carouselIntermediateDM[currentKpisDimensionKey].maxValue = 0.0;
                        //     }
                        //     carouselIntermediateDM[currentKpisDimensionKey].maxValue =
                        //         carouselIntermediateDM[currentKpisDimensionKey].maxValue > currDataPoint[5]._value ?
                        //             carouselIntermediateDM[currentKpisDimensionKey].maxValue : currDataPoint[5]._value;
                        //     if (carouselIntermediateDM[currentKpisDimensionKey].countAvg == undefined) {
                        //         carouselIntermediateDM[currentKpisDimensionKey].countAvg = 0;
                        //     }
                        //     if (currDataPoint[5]._value) {
                        //         carouselIntermediateDM[currentKpisDimensionKey].countAvg++;
                        //     }
                        // }

                        // //Populating Carousel DM : Previous time range sum
                        // if (currDataPoint[4]._value >= moment(startDateConsidered).subtract(1, 'months').format('YYYYMMDD')
                        //     && currDataPoint[4]._value <= moment(endDateConsidered).subtract(1, 'months').format('YYYYMMDD')
                        //     && -1 !== findIndex(finalKpisMultiSelectValues, function (o) { return o.name == currDataPoint[2]._formattedValue; })
                        // ) {
                        //     let currentKpisDimensionKey = currDataPoint[2]._formattedValue;
                        //     if (!carouselIntermediateDM[currentKpisDimensionKey]) {
                        //         carouselIntermediateDM[currentKpisDimensionKey] = {}
                        //     }
                        //     if (carouselIntermediateDM[currentKpisDimensionKey].prevSum == undefined) {
                        //         carouselIntermediateDM[currentKpisDimensionKey].prevSum = 0.0;
                        //     }
                        //     carouselIntermediateDM[currentKpisDimensionKey].prevSum += currDataPoint[5]._value;
                        // }

                        // //Populating Carousel DM : Sparkline Variation Y AXIS
                        // if (currDataPoint[4]._value >= moment(startDateConsidered).format('YYYYMMDD')
                        //     && currDataPoint[4]._value <= moment(endDateConsidered).format('YYYYMMDD')
                        //     && -1 !== findIndex(finalKpisMultiSelectValues, function (o) { return o.name == currDataPoint[2]._formattedValue; })
                        // ) {
                        //     let currentKpisDimensionKey = currDataPoint[2]._formattedValue;
                        //     if (!carouselIntermediateDM[currentKpisDimensionKey]) {
                        //         carouselIntermediateDM[currentKpisDimensionKey] = {}
                        //     }
                        //     if (carouselIntermediateDM[currentKpisDimensionKey].sparkVar == undefined) {
                        //         carouselIntermediateDM[currentKpisDimensionKey].sparkVar = [];
                        //     }
                        //     if (carouselIntermediateDM[currentKpisDimensionKey].sparkXAxis == undefined) {
                        //         carouselIntermediateDM[currentKpisDimensionKey].sparkXAxis = [];
                        //     }
                        //     carouselIntermediateDM[currentKpisDimensionKey].sparkXAxis.push(currDataPoint[4]._formattedValue);
                        //     carouselIntermediateDM[currentKpisDimensionKey].sparkVar.push(currDataPoint[5]._value);
                        // }

                    });

                    console.log("Final KPIS Multi Select Values", finalKpisMultiSelectValues);
                    
                    each(relevantSheetData._columns, (currDataPoint)=> {

                        if(-1!==findIndex(finalKpisMultiSelectValues, function (o) { return o.name == String(currDataPoint._fieldName).split(/\((.*?)\)/g)[1]; })) {

                            let currentKpisDimensionKey = String(currDataPoint._fieldName).split(/\((.*?)\)/g)[1];
                            let currentKpisDimensionKeyIndex = currDataPoint._index;

                            console.log("Current KPIS Dimension Key", currentKpisDimensionKey);
                            
                            // Populating Carousel DM Sum for Test Worksheet

                            map(relevantSheetData._data, (presentDataPoint)=> {
                                if ((moment(presentDataPoint[dateIndex]._value).format('YYYYMMDD') >= moment(startDateConsidered).format('YYYYMMDD'))
                                    && (moment(presentDataPoint[dateIndex]._value).format('YYYYMMDD') <= moment(endDateConsidered).format('YYYYMMDD'))
                                ) {

                                    // console.log("Present Data Point", presentDataPoint);
                                    if (!carouselIntermediateDM[currentKpisDimensionKey]) {
                                        carouselIntermediateDM[currentKpisDimensionKey] = {}
                                    }
                                    if (carouselIntermediateDM[currentKpisDimensionKey].sum == undefined) {
                                        carouselIntermediateDM[currentKpisDimensionKey].sum = 0.0;
                                    }
                                    carouselIntermediateDM[currentKpisDimensionKey].sum += presentDataPoint[currentKpisDimensionKeyIndex]._value;
                                    if (carouselIntermediateDM[currentKpisDimensionKey].maxValue == undefined) {
                                        carouselIntermediateDM[currentKpisDimensionKey].maxValue = 0.0;
                                    }
                                    carouselIntermediateDM[currentKpisDimensionKey].maxValue =
                                        carouselIntermediateDM[currentKpisDimensionKey].maxValue > presentDataPoint[currentKpisDimensionKeyIndex]._value ?
                                            carouselIntermediateDM[currentKpisDimensionKey].maxValue : presentDataPoint[currentKpisDimensionKeyIndex]._value;
                                    if (carouselIntermediateDM[currentKpisDimensionKey].countAvg == undefined) {
                                        carouselIntermediateDM[currentKpisDimensionKey].countAvg = 0;
                                    }
                                    if (presentDataPoint[currentKpisDimensionKeyIndex]._value) {
                                        carouselIntermediateDM[currentKpisDimensionKey].countAvg++;
                                    }

                                }
                            });

                            // Populating Carousel DM : Previous time range sum for Test Worksheet

                            map(relevantSheetData._data, (presentDataPoint)=> {
                            if ((moment(presentDataPoint[dateIndex]._value).format('YYYYMMDD') >= moment(startDateConsidered).subtract(12, 'months').format('YYYYMMDD'))
                                && (moment(presentDataPoint[dateIndex]._value).format('YYYYMMDD') <= moment(endDateConsidered).subtract(12, 'months').format('YYYYMMDD'))
                            ) {

                                // console.log("Present Data Point", presentDataPoint);
                                if (!carouselIntermediateDM[currentKpisDimensionKey]) {
                                    carouselIntermediateDM[currentKpisDimensionKey] = {}
                                }
                                if (carouselIntermediateDM[currentKpisDimensionKey].prevSum == undefined) {
                                    carouselIntermediateDM[currentKpisDimensionKey].prevSum = 0.0;
                                }
                                carouselIntermediateDM[currentKpisDimensionKey].prevSum += presentDataPoint[currentKpisDimensionKeyIndex]._value;
                                
                                }
                            });

                            console.log("Previous Sum:", carouselIntermediateDM[currentKpisDimensionKey].prevSum);

                            // Populating Carousel DM : Sparkline for Test Worksheet

                            let memory = {};

                            each(relevantSheetData._data, (currentData, idx)=> {
                            if ((moment(currentData[dateIndex]._value).format('YYYYMMDD') >= moment(startDateConsidered).format('YYYYMMDD'))
                                && (moment(currentData[dateIndex]._value).format('YYYYMMDD') <= moment(endDateConsidered).format('YYYYMMDD'))
                            ) {

                                    let dateStr = currentData[dateIndex]._formattedValue;

                                    if(!memory[dateStr]) {
                                        memory[dateStr] = {};
                                    }
                                
                                  each(relevantSheetData._columns, (currentCol, currentColIdx)=>{
                                    if(currentColIdx==currentKpisDimensionKeyIndex) {
                                        let columnKey = String(currentCol._fieldName).split(/\((.*?)\)/g)[1];
                                        let columnIndex = currentCol._index;
                                        if(!memory[dateStr][columnKey]) {
                                            memory[dateStr][columnKey] = 0;
                                        }
                                        // if(currentData[columnIndex]._formattedValue.includes("%") == true) {
                                        //         memory[dateStr][columnKey] += currentData[columnIndex]._value*100;
                                        //     } else {
                                        
                                        memory[dateStr][columnKey] += currentData[columnIndex]._value;
                                        
                                        // }
                                        }
                                    
                                  });

                                }

                            });

                            // Populating Carousel DM for prefix and suffix

                            map(relevantSheetData._data, (presentDataPoint)=> {
                                if ((moment(presentDataPoint[dateIndex]._value).format('YYYYMMDD') >= moment(startDateConsidered).format('YYYYMMDD'))
                                    && (moment(presentDataPoint[dateIndex]._value).format('YYYYMMDD') <= moment(endDateConsidered).format('YYYYMMDD'))
                                ) {
                                    if (!carouselIntermediateDM[currentKpisDimensionKey]) {
                                        carouselIntermediateDM[currentKpisDimensionKey] = {}
                                    }
                                    if (carouselIntermediateDM[currentKpisDimensionKey].prefix == undefined) {
                                        carouselIntermediateDM[currentKpisDimensionKey].prefix = "";
                                    }
                                    if (carouselIntermediateDM[currentKpisDimensionKey].suffix == undefined) {
                                        carouselIntermediateDM[currentKpisDimensionKey].suffix = "";
                                    }

                                    let formatted = presentDataPoint[currentKpisDimensionKeyIndex]._formattedValue;
                                    if(formatted.includes("$")==true) {
                                        carouselIntermediateDM[currentKpisDimensionKey].prefix = "$";
                                    }
                                    if(formatted.includes("¥")==true) {
                                        carouselIntermediateDM[currentKpisDimensionKey].prefix = "¥";
                                    }
                                    if(formatted.includes("₹")==true) {
                                        carouselIntermediateDM[currentKpisDimensionKey].prefix = "₹";
                                    }
                                    if(formatted.includes("€")==true) {
                                        carouselIntermediateDM[currentKpisDimensionKey].prefix = "€";
                                    }
                                    if(formatted.includes("£")==true) {
                                        carouselIntermediateDM[currentKpisDimensionKey].prefix = "£";
                                    }
                                    if(formatted.includes("%")==true) {
                                        carouselIntermediateDM[currentKpisDimensionKey].suffix = "%";
                                    }
                                }
                            });

                            // let xSparkAxis = Object.keys(memory);
                            let ySpark = Object.values(memory);

                            let ySparkRefined = map(ySpark, (object)=> {
                                return object[currentKpisDimensionKey];
                            });
                            console.log("YSparkAxis", ySparkRefined);

                            if(!carouselIntermediateDM[currentKpisDimensionKey].xSparkAxis) {
                                carouselIntermediateDM[currentKpisDimensionKey].xSparkAxis = [];
                            }

                            if(!carouselIntermediateDM[currentKpisDimensionKey].ySparkAxis) {
                                carouselIntermediateDM[currentKpisDimensionKey].ySparkAxis = [];
                            }

                            carouselIntermediateDM[currentKpisDimensionKey].xSparkAxis.push(Object.keys(memory));
                            carouselIntermediateDM[currentKpisDimensionKey].ySparkAxis.push(ySparkRefined);
                        
                        }
                    });

                    console.log("Carousel Intermediate DM", carouselIntermediateDM);


                    // let splineFinalDM = reduce(splineIntermediateDM, (acc, v, k) => {
                    //     acc.xAxisArr = v.xAxisArr;
                    //     acc.yAxisArr.push({
                    //         name: k,
                    //         color: getRandomColor(),
                    //         marker: {
                    //             enabled: false
                    //         },
                    //         data: v.yAxisArr
                    //     });
                    //     acc.yAxisFormattedArr.push(v.yAxisFormattedArr);
                    //     return acc;
                    // }, {
                    //     xAxisArr: [],
                    //     yAxisArr: [],
                    //     yAxisFormattedArr: []
                    // });

                    //New Spline Final DM ---->>>>>>>>>
                    
                    let finalXAxisSortedArray = chain(splineIntermediateDM)
                        .map(v => v.xAxisArr)
                        .flatten()
                        .uniq()
                        // .sortBy(v => moment(v, "MMMM DD, YYYY").unix())
                        .sortBy(v => moment(v, "DD MMMM YYYY").unix())
                        .value();
                    let splineFinalDM = reduce(splineIntermediateDM, (acc, v, k) => {
                        acc.xAxisArr = finalXAxisSortedArray;
                        let currOYAxisArr = new Array(finalXAxisSortedArray.length).fill(null);
                        // let currOYAxisFormattedArr = new Array(finalXAxisSortedArray.length).fill(null);
                        each(v.xAxisArr, (currX, currXIdx) => {
                          let currXIdxInFinalArr = indexOf(finalXAxisSortedArray, currX);
                          if(currXIdxInFinalArr != -1){
                            currOYAxisArr[currXIdxInFinalArr] = v.yAxisArr[currXIdx];
                            // currOYAxisFormattedArr[currXIdxInFinalArr] = v.yAxisFormattedArr[currXIdx];
                          } else {
                            console.error("Current Object X not found in final X Axis Array", currX, finalXAxisSortedArray);
                          }
                        });
                        acc.yAxisArr.push({
                            name: k,
                            color: getRandomColor(),
                            marker: {
                                enabled: false
                            },
                            data: currOYAxisArr
                        });
                        acc.yAxisFormattedArr.push(v.yAxisFormattedArr);
                        return acc;
                    }, {
                        xAxisArr: [],
                        yAxisArr: [],
                        yAxisFormattedArr: []
                    });

                    splineFinalDM.metric = finalSelectedKpiMetric;
                    splineFinalDM.dimension = finalDimension;

                    console.log("Spline Intermediate" , splineIntermediateDM, splineFinalDM);


                    let carouselFinalDM = reduce(carouselIntermediateDM, (acc, v, k) => {
                        if (v.prevSum == undefined) {
                            v.prevSum = 0;
                        };
                        let currKConf = formattingConf[k.toLowerCase()];
                        if (!currKConf) {
                            currKConf = formattingConf.default;
                        }
                        let formattedSumValue = formatStringFromConf(v.sum, currKConf);
                        let formattedPrevSumValue = formatStringFromConf(v.prevSum, currKConf);

                        // For percentage value of comparison between previous & current month
                        // let comparison = Math.abs((((v.prevSum-v.sum)/v.prevSum)*100).toFixed(2));
                        let comparison = (((v.sum-v.prevSum)/v.prevSum)*100).toFixed(2);
                        if (v.prevSum == undefined || comparison == Infinity) {
                            comparison = 0;
                        }
                        
                        if (formattedPrevSumValue == undefined) {
                            formattedPrevSumValue = 0;
                        };
                        let formattedGoalValue = formatStringFromConf(v.maxValue * v.countAvg, currKConf);
                        
                        let vari = ((v.sum / (v.maxValue * v.countAvg)) * 100).toFixed(2);
                        if (((v.sum / (v.maxValue * v.countAvg)) * 100) > 100) {
                            vari = 100;
                        };

                        let sparkv = v.ySparkAxis;
                        let sparkx = v.xSparkAxis;
                        let prefix = v.prefix;
                        let suffix = v.suffix;

                        acc.push({
                            title: k,
                            value: formattedSumValue,
                            // previousValue: formattedPrevSumValue,
                            comparison: comparison + "%",
                            change: (v.sum >= v.prevSum) ? 1 : 0,
                            goal: formattedGoalValue,
                            variance: vari + "%",
                            sparkv: sparkv,
                            sparkx: sparkx,
                            prefix: prefix,
                            suffix: suffix
                        });
                        return acc;
                    }, []);
                    // console.log("splineDM", splineFinalDM, carouselFinalDM);
                    setSplineDM(splineFinalDM);
                    setCarouselDM(carouselFinalDM);
                }
            }
        }
    };

    let getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const handleChartGeneration = (chartGenerationDM) => {
        changeStartDateRecalculation(chartGenerationDM);
        setChartGenerationDMState(chartGenerationDM);
        getChartDMFromGenerationDM(chartGenerationDM);
    }

    const recalculateChart = (startDateParam, endDateParam) => {
        if (startDateParam && endDateParam && chartGenerationDMState) {
            getChartDMFromGenerationDM(chartGenerationDMState, startDateParam, endDateParam);
        }
    }

    if (!getStartedDM) {
        generateGetStartedDM();
    }

    if (dashboardSheets && dashboardSheetsData && chartGenerationDMState && startDate && endDate) {
        if (!splineDM && !carouselDM) {
            getChartDMFromGenerationDM(chartGenerationDMState);
        } else if (!redirectToCharts) {
            // console.log("Redirecting to Charts");
            setRedirectToCharts(true);
        }
    }


    const handleSettingRemoval = () => {
        console.log("Setting to remove and  redirect on getstarted page")

        tableau.extensions.settings.erase('wSheet')
        tableau.extensions.settings.erase('multiSelectList1')
        tableau.extensions.settings.erase('multiSelectList2')
        tableau.extensions.settings.erase('kpiDimenMetric')
        tableau.extensions.settings.erase('finalSavedDimension')
        tableau.extensions.settings.erase('savedStartDate') 
        tableau.extensions.settings.erase('savedEndDate')
        tableau.extensions.settings.erase('selectedSavingOption')
        tableau.extensions.settings.saveAsync();
           
        setRedirectToGetStarted(true)
        setRedirectToCharts(false)

        toast.success('The settings have been reset. You may need to reload the dashboard/extension', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
    }

    return (
        <React.Fragment>
            
            {redirectToCharts==true ? (
                <>
                <button className="createBtn2" onClick={handleSettingRemoval}>Reset setting</button>
                <HeaderContainer
                    wrapperClassName="header-class"
                    handleSettingRemoval={handleSettingRemoval}
                />
                </>
            ) : (
               
                
                <HeaderContainer
                    wrapperClassName="noRedirect-header-class"
                    
                />
                
            )}

                {/* <DatePicker
                    wrapperClassName="date-picker"
                    selected={startDate}
                    onChange={onDateRangeUpdate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    isClearable
                    showMonthDropdown
                    showYearDropdown
                    placeholderText="Date"
                    dateFormat="d MMM yy"
                // dateFormat="yyyyMMdd"
                /> */}

            {redirectToCharts===true && redirectToGetStarted===false ? (
                <DatePicker
                    wrapperClassName="date-picker"
                    selected={startDate}
                    onChange={onDateRangeUpdate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    isClearable
                    showMonthDropdown
                    showYearDropdown
                    placeholderText="Date"
                    dateFormat="d MMM yy"
                // dateFormat="yyyyMMdd"
                />
            ) : (
                <DatePicker
                    wrapperClassName="noRedirect-date-picker"
                    selected={startDate}
                    onChange={onDateRangeUpdate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    isClearable
                    showMonthDropdown
                    showYearDropdown
                    placeholderText="Date"
                    dateFormat="d MMM yy"
                    // dateFormat="yyyyMMdd"
                />
            )}
           
            <Router >
            
                    <Route exact path="/">
                    {redirectToGetStarted ? <Redirect to="/" /> : redirectToCharts ? <Redirect to="/charts" /> : null}
                        
                        <GetStarted dm={getStartedDM}
                            handleGenerateChart={handleChartGeneration}></GetStarted>
                    </Route>
                    <Route exact path="/charts">
                        <Carousel dm={carouselDM}></Carousel>
                        <Spline dm={splineDM}></Spline>
                    </Route>
                    
                   

            </Router>
            

        </React.Fragment>
    )
}

export default HomeComponent;
