import React from 'react';

const Header = () => {

    return(
        <div className='footer-wrapper'>
            <div className='help-wrapper'>
                <div className='icon icon-large icon-help'></div>
                <a href='mailto:engineering@decisionfoundry.com'>Have Questions?</a>
            </div>
            <div>&copy; {(new Date().getFullYear())} <a href="https://www.decisionfoundry.com" target="_blank" rel="noreferrer">Decision Foundry Dashboard Templates</a></div>
        </div>
    );
}

export default Header;
