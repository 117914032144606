import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsNoData from 'highcharts/modules/no-data-to-display';

// const Spline = (props) => {
//     let xAxisArrOpts = [];
//     let yAxisArrOpts = [];

// try {
//     xAxisArrOpts = props.location.state.dimensions.xAxisArr;
//     yAxisArrOpts = props.location.state.dimensions.yAxisArr;
// } catch (e) {
//     console.error("props.location.state.kpis not found for slides, setting slides=[]");
// }

// const options = { series: yAxisArrOpts,
//     xAxis: {
//                     categories: xAxisArrOpts,
//                     labels: {
//                         style: {
//                             color: 'rgba(255,255,255,0.6)'
//                         }
//                     }
//                 },
// }

const Spline = (props) => {

    console.log("Spline Props", props);

    const [options, setOptions] = useState(
        {
            credits: {
                enabled: false
            },
            title: {
                text: null
            },
            chart: {
                backgroundColor: 'rgba(18,18,18,0.4)',
                type: 'spline',
                spacing: [40, 40, 40, 40],
                events: {
                    load: function () {
                        let items = document.getElementsByClassName('highcharts-legend-item');
                        for (let i = 0; i < items.length; i++) {
                            items[i].querySelector('path').setAttribute('stroke-width', 10);
                            items[i].querySelector('path').setAttribute('d', 'M 10 10 m -5 0 a 2 2 0 0 0 0 0');
                            items[i].querySelector('path').setAttribute("stroke-linecap", "round");
                        }
                    },
                    redraw: function () {
                        let items = document.getElementsByClassName('highcharts-legend-item');
                        for (let i = 0; i < items.length; i++) {
                            items[i].querySelector('path').setAttribute('stroke-width', 10);
                            items[i].querySelector('path').setAttribute('d', 'M 10 10 m -5 0 a 2 2 0 0 0 0 0');
                            items[i].querySelector('path').setAttribute("stroke-linecap", "round");
                        }
                    }
                },
                // legend: {
                //     align: 'right',
                //     verticalAlign: 'top',
                //     itemStyle: {
                //         color: '#fff'
                //     },
                //     itemHoverStyle: {
                //         color: 'rgba(255,255,255,0.8)'
                //     },
                //     symbolHeight: 12,
                //     symbolWidth: 12,
                //     symbolRadius: 6
                // },
                // xAxis: {
                //     categories: [],
                //     labels: {
                //         style: {
                //             color: 'rgba(255,255,255,0.6)'
                //         }
                //     }
                // },
                // yAxis: {
                //     title: {
                //         align: 'high',
                //         offset: -100,
                //         text: 'Cost by Campaigns',
                //         rotation: 0,
                //         x: 0,
                //         y: -30,
                //         style: {
                //             color: '#fff',
                //             fontSize: '14px'
                //         }
                //     },
                //     gridLineColor: 'rgba(255,255,255,0.2)',
                //     labels: {
                //         style: {
                //             color: 'rgba(255,255,255,0.6)'
                //         }
                //     }
                // },
                series: [],
                noData: {
                    style: {
                        fontWeight: 'normal',
                        fontSize: '12px',
                        color: '#fff'
                    }
                },
                lang: {
                    noData: "No data available",
                    decimalPoint: '.'
                },
            }
        });

    useEffect(() => {
        // console.log("Props", props);
        // let xAxisArrOpts = props.location.state.dimensions.xAxisArr;
        // let yAxisArrOpts = props.location.state.dimensions.yAxisArr;
        let xAxisArrOpts = props.dm.xAxisArr;
        let yAxisArrOpts = props.dm.yAxisArr;
        let yAxisFormattedArrOpts = props.dm.yAxisFormattedArr;
        // let metric = props.location.state.metric;
        let metric = props.dm.metric;
        let dimension = props.dm.dimension;

        setOptions({
            legend: {
                align: 'right',
                verticalAlign: 'top',
                itemStyle: {
                    color: '#fff'
                },
                itemHoverStyle: {
                    color: 'rgba(255,255,255,0.8)'
                },
                symbolHeight: 12,
                symbolWidth: 12,
                symbolRadius: 6
            },
            xAxis: {
                categories: xAxisArrOpts,
                labels: {
                    style: {
                        color: 'rgba(255,255,255,0.6)'
                    }
                }
            },
            yAxis: {
                title: {
                    align: 'high',
                    offset: -100,
                    text: metric + ' by ' + dimension,
                    rotation: 0,
                    x: 0,
                    y: -40,
                    style: {
                        color: '#fff',
                        fontSize: '14px'
                    }
                },
                gridLineColor: 'rgba(255,255,255,0.2)',
                labels: {
                    style: {
                        color: 'rgba(255,255,255,0.6)'
                    },
                    formatter: function() {
                        var prefixLabel = "";
                        var suffixLabel = "";
                        if(yAxisFormattedArrOpts[0][0].includes("$")==true) {
                            prefixLabel = "$";
                        }
                        if(yAxisFormattedArrOpts[0][0].includes("€")==true) {
                            prefixLabel = "€";
                        }
                        if(yAxisFormattedArrOpts[0][0].includes("₹")==true) {
                            prefixLabel = "₹";
                        }
                        if(yAxisFormattedArrOpts[0][0].includes("¥")==true) {
                            prefixLabel = "¥";
                        }
                        if(yAxisFormattedArrOpts[0][0].includes("£")==true) {
                            prefixLabel = "£";
                        }
                        if(yAxisFormattedArrOpts[0][0].includes("%")==true) {
                            suffixLabel = "%";
                        }

                        return prefixLabel + this.axis.defaultLabelFormatter.call(this) + suffixLabel;
                        
                        
                        // if(metric.toLowerCase() === 'ctr') {
                        //     return this.axis.defaultLabelFormatter.call(this) + '%';
                        // } else if(metric.toLowerCase() === 'cost' || metric.toLowerCase() === 'revenue') {
                        //     return '$' + this.axis.defaultLabelFormatter.call(this);
                        // } else {
                        //     return this.axis.defaultLabelFormatter.call(this);
                        // }
                    }
                }
            },

            plotOptions: {
                series: {
                    connectNulls: true
                }
            },

            series: yAxisArrOpts,
            
            tooltip: {
                formatter: function() {
                    var prefixTooltip = "";
                    var suffixTooltip = "";
                    if(yAxisFormattedArrOpts[0][0].includes("$")==true) {
                        prefixTooltip = "$";
                    }
                    if(yAxisFormattedArrOpts[0][0].includes("€")==true) {
                        prefixTooltip = "€";
                    }
                    if(yAxisFormattedArrOpts[0][0].includes("₹")==true) {
                        prefixTooltip = "₹";
                    }
                    if(yAxisFormattedArrOpts[0][0].includes("¥")==true) {
                        prefixTooltip = "¥";
                    }
                    if(yAxisFormattedArrOpts[0][0].includes("£")==true) {
                        prefixTooltip = "£";
                    }
                    if(yAxisFormattedArrOpts[0][0].includes("%")==true) {
                        suffixTooltip = "%";
                    }
                    
                    return this.x + '<br/>' + prefixTooltip + this.y.toLocaleString() + suffixTooltip;

                    // if(metric.toLowerCase() === 'ctr') {
                    //     return this.x + '<br/>' + this.y.toLocaleString() + '%';
                    // } else if(metric.toLowerCase() === 'cost' || metric.toLowerCase() === 'revenue') {
                    //     return this.x + '<br/>' + '$' + this.y.toLocaleString();
                    // } else {
                    //     return this.x + '<br/>' + this.y.toLocaleString();
                    // }
                },
                useHTML: true
            },
        });
    }, [props]);

    // console.log("Spline Highcharts", Highcharts);
    console.log("Spline Options", options);

    HighchartsNoData(Highcharts);
    return (
        <div className='highcharts-spline'>
            <h4>Let's look at the trend</h4>
            <div className='splineOuter'>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
            
            <div className="splineNote">
                Please note :
                <ul classname = "noteList">
                    <li>The KPIs and sparklines are based on the selected date range and the date aggregation set on the sheet.</li>
                    <li>The dashboard will consider a whole week/month/quarter/year even if a half is selected with the corresponding aggregation on the sheet.</li>
                    <li>The dashboard will default populate data for the last three months.</li>
                    <li>The percentage changes on the KPI cards are derived after comparing them with the same period the previous year.</li>
                </ul>
            </div>
        </div>
    );
}

export default Spline;
