import Home from '../components/HomeComponent'
import {connect} from 'react-redux'
import {storeImageBase64} from '../actions/actions'

const mapStateToProps=state=>({
})

const mapDispatchToProps=dispatch=>({
    storeImageBase64Handler:data=>dispatch(storeImageBase64(data))

})
export default connect(mapStateToProps,mapDispatchToProps)(Home)
